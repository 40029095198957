<template>
  <div class="content-result">
    <!-- <div> -->
    <div class="center tip-text">
      <span v-if="!beginQuery">
        比赛成绩未公布，比赛成绩公布时间:{{
          $moment(this.matchDetail.currentStage.scoreQueryBegin).format(
            'YYYY-MM-DD HH:mm',
          )
        }}
      </span>
      <span v-if="beginQuery && tipInfo">{{ tipInfo }}</span>
      <span v-if="hasFinal">恭喜您进入了复赛 </span>
    </div>
    <div class="result-infos">
      <el-select
        v-model="currentStage"
        @change="onStageChange"
        placeholder="请选择"
      >
        <el-option
          v-for="item in stageOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="center result-work-infos">
        <div v-for="(work, index) in works" :key="index" class="work-info">
          <span class="info-title">{{
            index + 1 + '.' + work.label + ':'
          }}</span>
          <span class="info-content">{{ workData[work.key] }}</span>
        </div>
      </div>
    </div>
    <div class="result-table" v-if="hasScore">
      <el-table
        ref="singleTable"
        :data="resultData"
        highlight-current-row
        show-summary
        :summary-method="getSummaries"
        style="width: 100%"
      >
        <el-table-column
          v-for="(schema,i) in tableSchema"
          :key="i"
          :property="schema.property"
          :label="schema.label"
          :width="schema.width"
          align="center"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { getMyResult } from '@/api/match';
export default {
  name:"MyResultSingle",
  props: {
    teamId:null
  },
  data() {
    return {
      hasScore:true,
      totalScore:0,
      isApply:true,
      beginQuery: false,
      hasFinal: false, //是否进入下一阶段
      currentStage: '',
      tipInfo: '', //提示信息
      stageOptions: [],
      resultData: [],
      workData: {},
      tableSchema: [
        { label: '评价指标', property: 'name', width: '600' },
        { label: '指标分数', property: 'maxScore', width: '300' },
        { label: '作品得分', property: 'score', width: '100' },
      ],
      works: [
        { label: '团队名称', key: 'team_name' },
        { label: '参赛组别', key: 'level_id' },
        { label: '作品名称', key: 'work_title' },
        { label: '作品说明', key: 'description' },
        // { label: '作品附件', key: 'file_name' },
      ],
    };
  },
  computed: {
    matchDetail() {
      return this.$store.state.matchDetail;
    },
  },
  created: function () {
    const { stages, currentStage } = this.matchDetail;
    const { id } = currentStage;
    //默认获取当前阶段的成绩
    this.getMyResult({ matchId: this.matchDetail.id, stageId: id,teamId:this.teamId });
    //设置下拉框为当前阶段
    let stageOptions = [];
    stages.map((stage) => {
      stageOptions.push({
        label: stage.name,
        value: stage.id,
      });
    });
    this.stageOptions = stageOptions;
    this.currentStage = id;
  },
  methods: {
    getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总分';
            return;
          }
        });
          sums[2] = this.totalScore;
        return sums;
      },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //合并行
      if (columnIndex === 0) {
        return {
          rowspan: row.span,
          colspan: row.span ? 1 : 0,
        };
      }
    },
    onStageChange(val) {
      const params = { stageId: val, matchId: this.matchDetail.id };
      this.getMyResult(params);
    },
    getMyResult(params) {
      //1 是否可以开始查询成绩
      let scoreQueryBegin = '';
      this.tipInfo = '';
      const { stages } = this.matchDetail;
      stages.map((stage) => {
        if (params.stageId === stage.id) {
          scoreQueryBegin = stage.scoreQueryBegin;
        }
      });
      const nowTime = this.$moment(new Date()).valueOf();
      const queryScoreTime = this.$moment(scoreQueryBegin).valueOf();
      // if (nowTime < queryScoreTime) {
      //   return;
      // }
      //2 查询成绩
      this.beginQuery = true;
      getMyResult(params).then((res) => {
        const { message, success, result } = res;
        //2.1 没有成绩，进行错误提示
        if (!success) {
          this.hasScore = false;
          this.tipInfo = message;
          return;
        }
        this.hasScore = true
        if(result.isPass&&result.isPass==1){
          this.tipInfo = '恭喜您，进入下一个比赛阶段'
        }
        //2.2 回填作品信息
        this.workData = {
          team_name:result.teamName,
          level_id:result.matchLevelId,
          work_title:result.workName,
          description:result.workDescription,
          file_name:result.file_name,
        };
        this.resultData = [];
        try{
          let tableData = JSON.parse(result.ruleValueAver);
          this.resultData = tableData.score;
          this.totalScore = result.score;
        }catch(err){
        }
        // this.resultData = resultData.score;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
