<template>
  <div class="content-table">
    <div class="center tip-text">
      <span v-if="!beginQuery && activeMenu === 'result'">
        比赛成绩未公布，比赛成绩公布时间:{{
          $moment(this.matchDetail.currentStage.scoreQueryBegin).format(
            'YYYY-MM-DD HH:mm',
          )
        }}
      </span>
    </div>
    <div class="table-list">
      <!-- <el-select
        v-if="activeMenu === 'result' && hasFinal"
        v-model="stage"
        placeholder="请选择"
        @change="changeStage"
        style="width:90px;margin-right:20px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->

      <el-table
      border
        ref="singleTable"
        :data="dataList"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column
          v-for="schema in tableSchema[tableType]"
          :key="schema.property"
          :property="schema.property"
          :type="schema.property"
          :label="schema.label"
          :width="schema.width"
          align="center"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="table-pagination">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="page.total"
        :current-page="pageNo"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getTeamList, getResultList } from '@/api/match';
export default {
  name:"matchTable",
  props: {
    activeMenu: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      dataList: [],
      page: { total: 1 },
      beginQuery: false,
      tableSchema: {
        team: [
          { label: '序号', property: 'index', width: '50' },
          { label: '团队名称', property: 'teamName', width: '200' },
          { label: '组别', property: 'groupType', width: '150' },
          { label: '指导老师', property: 'instructor', width: '200' },
          { label: '团队成员', property: 'members', width: '200' },
          { label: '学校', property: 'school', width: '200' },
        ],
        result: [
          { label: '序号', property: 'index', width: '50' },
          { label: '团队名称', property: 'teamName', width: '250' },
          { label: '组别', property: 'groupType', width: '150' },
          { label: '指导老师', property: 'instructor', width: '200' },
          { label: '团队成员', property: 'members', width: '250' },
          { label: '作品得分', property: 'score', width: '100' },
        ],
      },
    };
  },
  computed: {
    tableType: function () {
      return this.activeMenu === 'result' ? 'result' : 'team';
    },
    matchDetail() {
      return this.$store.state.matchDetail;
    },
  },
  created: function () {
    // console.log(this.dataList,this.matchDetail);
    this.getDataList();
  },
  methods: {
    getDataList(params) {
      if(!this.matchDetail || !this.matchDetail.currentStage){
        return;
      }
      const requestParams = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        matchId: this.matchDetail.id,
        stageId: this.matchDetail.currentStage.id,
        ...params,
      };
      const { scoreQueryBegin } = this.matchDetail.currentStage;
      const nowTime = this.$moment(new Date()).valueOf();
      const queryScoreTime = this.$moment(scoreQueryBegin).valueOf();
      switch (this.activeMenu) {
        case 'result':
          // if (nowTime < queryScoreTime) {
          //   return;
          // }
          this.beginQuery = true;
          getResultList(requestParams).then((res) => {
            if(res.success == false){
              return;
            }
            const { records = [] } = res.result;
            const dataList = [];
            records &&
              records.map((record) => {
                let members = '';
                let instructor = '';
                // record.members.map((member) => {
                //   members += member.name;
                // });
                instructor =
                  record.masterTutor + record.secondTutor
                    ? '、' + record.secondTutor
                    : '';
                const data = {
                  teamName: record.teamName,
                  groupType: record.matchLevelId,
                  instructor: instructor,
                  members: members,
                  score: record.score,
                };
                dataList.push(data);
              });
            this.dataList = dataList;
            this.page = { total: res.result.total };
          });
          break;
        default:
          getTeamList(requestParams).then((res) => {
             if(res.success == false){
              return;
            }
            const { records = [] } = res.result;
            const dataList = [];
            records &&
              records.map((record) => {
                let members = '';
                let instructor = '';
                record.members.map((member,index) => {
                  members += `${index===0 ? '' : '、'}${member.name}` ;
                }); 
                instructor = record.secondTutor
                  ? record.masterTutor + '、' + record.secondTutor
                  : record.masterTutor;
                const data = {
                  teamName: record.name,
                  groupType: record.matchLevelId,
                  instructor: instructor,
                  members: members,
                  school: record.members[0] ? record.members[0].school : '',
                };
                dataList.push(data);
              });
            this.dataList = dataList;
            this.page = { total: res.result.total };
          });
          break;
      }
    },
    handleCurrentChange(val) {
      this.getDataList({ pageNo: val });
      this.pageNo = val;
    },
    changeStage(value) {
      this.stageId = value;
      this.getDataList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/views/matchDetail.scss';
</style>
