import { axios } from '@/utils/request';
export const URL_API = 'http://47.112.101.124:8080/api';
// export const hideMatchId = '1515929983044206594';
export const hideMatchId = '1512317098131402754';
/**
赛事列表
 */
export function getMatchList(parameter) {
  return axios({
    url: '/v1/matchs',
    method: 'get',
    params: parameter,
  });
}

/**
赛事列表
 */
export function getMatchSeries(parameter) {
  return axios({
    url: '/v1/matchSeries',
    method: 'get',
    params: parameter,
  });
}
/**
赛事详情
 */
export function getMatchDetailById(parameter) {
  return axios({
    url: '/v1/match',
    method: 'get',
    params: parameter,
  });
}
/**
赛事列表
 */
export function sendApplyCode(parameter) {
  return axios({
    url: '/v1/sms',
    method: 'post',
    data: parameter,
  });
}
/**
报名参赛
 */
export function submitApplyInfo(parameter) {
  return axios({
    url: '/matching/apply',
    method: 'post',
    data: parameter,
  });
}
/**
获取报名的状态
 */
export function getApplySatus(parameter) {
  return axios({
    url: '/matching/status',
    method: 'get',
    params: parameter,
  });
}
/**
获取已报名团队列表
 */
export function getTeamList(parameter) {
  return axios({
    url: 'matching/teams',
    method: 'get',
    params: parameter,
  });
}
/**
提交作品
 */
export function submitWorks(parameter) {
  return axios({
    url: '/matching/submit',
    method: 'post',
    data: parameter,
  });
}
/**
作品状态
 */
export function getWorksStatus(parameter) {
  return axios({
    url: '/matching/submit/status',
    method: 'get',
    params: parameter,
  });
}
/**
提交投诉
 */
export function submitComplaint(parameter) {
  return axios({
    url: '/matching/complaint',
    method: 'get',
    data: parameter,
  });
}

/**
获取我的成绩
 */
export function getMyResult(parameter) {
  return axios({
    url: 'matching/workScore',
    method: 'get',
    params: parameter,
  });
}

/**
获取成绩列表
 */
export function getResultList(parameter) {
  return axios({
    url: 'matching/resultScore',
    method: 'get',
    params: parameter,
  });
}


/**
获取待评分列表
 */
export function getJudgeList(parameter) {
  return axios({
    url: 'matching/judgeWorkList',
    method: 'get',
    params: parameter,
  });
}

/**
获取待评分规则（）
 */
export function getJudgeRule(parameter) {
  return axios({
    url: 'matching/judgeRule',
    method: 'get',
    params: parameter,
  });
}

/**
提交评分
 */
export function submitJudgeScore(parameter) {
  return axios({
    url: 'matching/judge',
    method: 'post',
    data: parameter,
  });
}

/**
 * 获取投诉
 * @param {} parameter 
 * @returns 
 */
export function getComplaint(parameter) {
  return axios({
    url: 'matching/complaint',
    method: 'get',
    params: parameter,
  });
}

export function submitWorksMulti(parameter) {
  return axios({
    url: 'matching/submit/multi',
    method: 'post',
    data: parameter,
  });
}

export function editApplyDetail(param){
  return axios({
    url: 'matching/updateTeam',
    method: 'post',
    data: param,
  });
}

export function deleteApply(param){
  return axios({
    url: 'matching/deleteTeam?teamId='+param,
    method: 'post',
    data:param
  });
}

export function uploadAttachMent(data){
  return axios({
    url: 'matching/attachment/upload',
    method: 'post',
    data:data
  });
}